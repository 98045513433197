import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";





class ProfileAdmin extends Component {
    



    constructor(props) {
        super(props);
        this.state = {
            name:"",
            lastName:"",
            email:"",
            user: [],
            clubs: [],
           
            
             
            
        }

    }
    
   

    componentDidMount(){
     this.getUser();
     this.getUserClubs();
    

        


    }

    handleInput=(event)=> {
        this.setState({[event.target.id]: event.target.value});
        
    }
    
   
    getUser=()=>{
         Service.get("/users/getUserById",{id: Auth.userId}, (res) => {

            console.log(res);
            this.setState({user: res.data.user});
            
           
        }); 
    }

    


    
    getUserClubs=()=>{
        Service.get("/users/getUserClubs",{id: Auth.userId}, (res) => {

           console.log(res);
           this.setState({clubs: res.data.clubs});
           
        }); 
   }

    // updateImage=()=>{
    //     Service.get("/users/getUserClubs",{id: Auth.userId}, (res) => {

    //         console.log(res);
    //         this.setState({clubs: res.data.clubs});
            
    //      }); 

    // }


    updateUser=()=>{
        Service.get('/users/updateName',{
            name: this.state.name,
            lastName: this.state.lastName,

            
            

        },(res)=>{
            console.log(res);
            if(res.success){
                this.setState({alert: 'You successfuly updated your bank account information.'})
            }else {
                this.setState({alert: 'error'})
            }
        })

    }

    render() {
       
        return (


            
             <div >
                 <h1>Profile Admin</h1>
               
                      
                    <p><h4>Welcome</h4> <h5>{this.state.user.name}</h5> </p>
                     <p>{this.state.user.lastName}</p>
                   
                 <br />


                 <h3>Change your infromations</h3>
                 <label>Name</label>
                <input type="text" id="name" value={this.state.name} onChange={this.handleInput} /><br />

                <label>Lastname</label>
                <input type="text" id="lastName" value={this.state.lastName} onChange={this.handleInput} /><br />
    
                <button className="defaultButton " onClick={this.updateUser}>Submit</button>

                <div>
                <label for="multi" class="btn">Choose File</label>
                 <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple data-buttonText={"Upload File"} />
              </div> 
              <div>
                  <h3>clubs</h3>
                  {this.state.clubs.map(function (item, index) {
                            return (
                                <div  key={index}>

                                <p>{item.name}</p>
                                <p>{item.description}</p>
                                <p>{item.address}</p>
                               

                                </div>   
                                      
                            )
                        }, this)
                        }
              </div>
              <Link to="/edit-club">Edit Club</Link>
            </div>
        );
    }

}

export default ProfileAdmin;