import React, { Component } from 'react';
import Service from '../components/Service';


class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: ''
        }
    }

    componentDidMount(){
        this.updateUsers();
    }

    updateUsers = () => {
    // console.log(response.picture.data.url, response.name, response.email, response.id);
        Service.get('/users/get',{

        }, (res)=>{
            console.log(res);
            if(res.success == true){
                this.setState({users: res.users});
            }else{
                console.log('error', res);
            }
        })
    }

    render() {
        return (
            <div>
                

                <h1>Users</h1>
            </div>
            
        );
    }

}

export default Users;