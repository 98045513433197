import React, { Component } from 'react';



class EditClub extends Component {





    render() {
        return (
            <div>
                <h1>Edit Club</h1>

            
            </div>
            
        );
    }

}

export default EditClub;