import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, Link } from "react-router-dom";
import {Auth} from './components/Auth';



import EditClub from './views/EditClub'
import Home from "./views/Home";
import Users from "./views/Users";
import Club from "./views/Club";
import LogIn from "./views/LogIn";
import Profile from "./views/Profile";



const checkLoginStatus = () => {
  if (localStorage.getItem('userId')) {
    Auth.isAuthenticated = true;
    Auth.userId = localStorage.getItem('userId');
    Auth.name = localStorage.getItem('name');
    Auth.email = localStorage.getItem('email');
    console.log('a', Auth);
  }else {
      Auth.isAuthenticated = false;
  }
}

checkLoginStatus()

class App extends Component {
  constructor(props){
      super(props);
      
  }

    componentDidMount(){
        //checkLoginStatus();
    }
  


  render() {
    
    return (
      
      <div className="wrapper">
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/club/:id/:room?" component={Club} />
          <Route path="/log-in" component={LogIn} />
          <Route path="/profile/:id?/:role?" component={Profile} />
          <Route path="/edit-club" component={EditClub} />
        </Router>
      </div>
    );
  }
}

export default App;