import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';



class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            text: ''
        }
    }

    handleText=(event)=>{
        event.stopPropagation();
        this.setState({text: event.target.value});
        console.log(event.target.value)
    }

    handleKeyPress = (event) => {
    if(event.key === 'Enter'){
        console.log('enter press here! ', this.state.text);
        this.updateText();
    }
    }

    updateText=()=>{
        Service.get('/users/updateText',{text: this.state.text},()=>{
            this.props.updateUsers();
            
            this.setState({text: ''});
        });
    }



    render() {
        return (
            <div>
                <img src={this.props.user.image} />
                <div className="text">{this.props.user.text}</div>
                {this.props.user.id === Auth.userId &&
                    <input type="text" onChange={this.handleText} value={this.state.text} onKeyPress={this.handleKeyPress}/>
                }
            </div>
            
        );
    }

}

export default User;