import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import Map from '../views/Map';

//import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';




class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            image: ''
        }
    }

    responseFacebook = (response) => {
        console.log(response.picture.data.url, response.name, response.email, response.id);
        Service.get('/users/fblogin',{
            image: response.picture.data.url, 
            name: response.name, 
            email: response.email, 
            fbid: response.id
        }, (res)=>{
            console.log(res);
            if(res.success == true){
                Auth.authenticate(res.data.id, res.data.name, res.data.email, res.data.image);
                this.setState({name: Auth.name, image: Auth.image});
                console.log('auth', Auth);
                console.log('ls', localStorage);
            }else{
                console.log('error', res);
            }
        })
    }

    



    render() {
        return (
            <div>
                
                

                <h1>CLUBBING</h1>
                <p>welcome {this.state.name}</p>
                <img src={this.state.image} />
                {/* {Auth.isAuthenticated === false &&
                <FacebookLogin
                    appId="3194355217254457"
                    autoLoad={true}
                    fields="name,email,picture"
                    // onClick={componentClicked}
                    callback={this.responseFacebook} />
                } */}

                {/* apiKey='AIzaSyBWnhCXaUiBJ3a58TB_LndoUCsrTkWXQ0g' */}
                <div className="map">
                    <Map />
                </div>


            </div>
            
        );
    }

}

export default Home;