import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';
import {Auth} from '../components/Auth';


class LogIn extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            name: '',
            id:'',
            role:''
        }
      
     

    }
    
    selectRole = (event) => {

        this.setState({ role: event.target.value }, () => {
            console.log(this.state);
        });

    };

   

     login = () => {
         
        
        // Auth.authenticate(this.props.match.params.id,this.state.name,this.state.email,this.state.image)
           

        
       
        Service.get("/users/fblogin",{email: this.state.email, password: this.state.password, name: this.state.name , role: this.state.role}, (res) => {
            if(res.success == true){
                Auth.authenticate(res.data.id, res.data.name, res.data.email, res.data.image);
               

            console.log(res);
            }
            
        }); 
       
      

       


        // console.log(this.state);
    }


    handleInput = (event) => {
        
        this.setState({ [event.target.id]: event.target.value });
      
         
      
    }
      
    render() {
        return (
         
               
            <div >
                
                <h1>CLUBBING</h1>

                <label for="name">Name</label>
                <input type="name" value={this.state.name} id='name' onKeyPress={this.defaultButton} onChange={this.handleInput} placeholder="Your Name"/>

                
                <label for="email">E-mail</label>
                <input type="email" value={this.state.email} id='email' onKeyPress={this.defaultButton} onChange={this.handleInput} placeholder="Your Email"/>

                

                <label for="email">Password</label>
                <input type="password" value={this.state.password} id='password' onKeyPress={this.defaultButton} onChange={this.handleInput} placeholder="Your Password"/>

                <label for="role">Role</label>
                <select id='role' value={this.state.role} onChange={this.selectRole}>
                            <option value=''>Choose profile</option>
                            <option value='1'>owner</option>
                            <option value='2'>admin</option>
                            <option value='3'>member</option>
                            <option value='4'>guest</option>
                        </select>

              
                
        
                <button onClick={this.login}>Sign in</button><br></br>

                <Link to="/profile">profile</Link>
                
                
            </div>
                

             



           
        );
    }

}

export default LogIn;