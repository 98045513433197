import {Auth} from '../components/Auth';

const Service = {

  api: "https://api.clubbing.cekaonica.com/api",
  media: "https://api.clubbing.cekaonica.com/media",


  get(url, params, cb=null){
    params.Auth = Auth;
    fetch(`${this.api}${url}`,
        {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {
                console.log('error', error);
            }
        )
    },

}

export default Service;