import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import ProfileOwner from '../components/ProfileOwner'
import ProfileAdmin from '../components/ProfileAdmin'
import ProfileMember from '../components/ProfileMember'
import ProfileGuest from '../components/ProfileGuest'
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {duix} from 'duix';



function ProfileSelector(props) {
     
    if (props.role == 1) {
      return <ProfileOwner/>;
    }  
    if (props.role == 2) {
        return <ProfileAdmin/>;;
    }
    if (props.role == 3) {
        return <ProfileMember/>;;
    }
     if (props.role == 4) {
        return <ProfileGuest/>;
     }  
    return <h1>No role has been given!</h1>;
}
  


class Profile extends Component {
    



    constructor(props) {
        super(props);
        this.state = {
            name:"",
            last_name:"",
            email:"",
            role:'1',
            user: [],
            clubs: [],
           
            
             
            
        }

    }
    
    unsubscribe = [];

    componentDidMount(){
     this.getUser();
     this.getUserClubs();
    
//      setTimeout(()=>{
//         this.setState({role: Auth.role})
//     }, 100);

//     this.unsubscribe[0] = duix.subscribe('changeRole', () => {
//         this.setState({role: Auth.role});
//    });
   
    

        


    }

    handleInput=(event)=> {
        this.setState({[event.target.id]: event.target.value});
        
    }
    
   
    getUser=()=>{
         Service.get("/users/getUserById",{id: Auth.userId}, (res) => {

            console.log(res);
            this.setState({user: res.data.user});
            
           
        }); 
    }

    


    
    getUserClubs=()=>{
        Service.get("/users/getUserClubs",{id: Auth.userId}, (res) => {

           console.log(res);
           this.setState({clubs: res.data.clubs});
           
        }); 
   }

    // updateImage=()=>{
    //     Service.get("/users/getUserClubs",{id: Auth.userId}, (res) => {

    //         console.log(res);
    //         this.setState({clubs: res.data.clubs});
            
    //      }); 

    // }


    updateUser=()=>{
        Service.get('/users/updateName',{
            name: this.state.name,
            lastName: this.state.last_name,

            
            

        },(res)=>{
            console.log(res);
            if(res.success){
                this.setState({alert: 'You successfuly updated your bank account information.'})
            }else {
                this.setState({alert: 'error'})
            }
        })

    }

    render() {
       
        return (


            <ProfileSelector role={this.state.role}/>
            // <div >
            //     <h1>Profile</h1>
               
                      
            //         <p><h4>Welcome</h4> <h5>{this.state.user.name}</h5> </p>
            //         <p>{this.state.user.lastName}</p>
                   
            //     <br />


            //     <h3>Change your infromations</h3>
            //     <label>Name</label>
            //     <input type="text" id="name" value={this.state.name} onChange={this.handleInput} /><br />

            //     <label>Lastname</label>
            //     <input type="text" id="lastName" value={this.state.lastName} onChange={this.handleInput} /><br />
    
            //     <button className="defaultButton " onClick={this.updateUser}>Submit</button>

            //     {/* <div>
            //     <label for="multi" class="btn">Choose File</label>
            //     <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple data-buttonText={"Upload File"} />
            //     </div> */}
            // </div>
        );
    }

}

export default Profile;