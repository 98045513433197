import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';


const mapStyles = {
  width: '100%',
  height: '100%',
};


class MapContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        clubs: [{latitude: 44.79722000, longitude: 20.44603100},
                {latitude: 44.819719, longitude: 20.461518}]
        }
    }

    componentDidMount(){
        this.updateClubs();
    }

    updateClubs=()=>{
        Service.get('/users/getClubs',{},(res)=>{
            console.log(res);
            if(res.success){
                this.setState({clubs: res.data.clubs});
            }

        })
    }

    handleMarker=(id)=>{
        console.log('club id: ',id);
        window.location = '/club/'+id;
    }

    displayMarkers = () => {
        return this.state.clubs.map((club, index) => {
            return <Marker key={index} id={index} position={{
                lat: club.latitude,
                lng: club.longitude
            }}
            onClick = {()=>this.handleMarker(club.id)}
            label={club.name}
            // onClick={() => console.log("You clicked me!")} 
            />
        })
    }


    

    render() {
        return (
            <Map
            google={this.props.google}
            zoom={12}
            style={mapStyles}
            initialCenter={{ lat: 44.79722000, lng: 20.44603100}} 
            >
            {this.displayMarkers()}
            </Map>
        );
    }

    // render() {
    //     <Map
    //       google={this.props.google}
    //       zoom={8}
    //       style={mapStyles}
    //       initialCenter={{ lat: 47.444, lng: -122.176}}
    //     />
    //     return (
    //         <div>
                
                

    //             <h1>CLUBBING</h1>
    //             <p>welcome {this.state.name}</p>
    //             <img src={this.state.image} />
    //             {Auth.isAuthenticated === false &&
    //             <FacebookLogin
    //                 appId="3194355217254457"
    //                 autoLoad={true}
    //                 fields="name,email,picture"
    //                 // onClick={componentClicked}
    //                 callback={this.responseFacebook} />
    //             }

    //             <Map 
    //                 google={this.props.google}
    //                 zoom={8}
    //                 apiKey='AIzaSyBWnhCXaUiBJ3a58TB_LndoUCsrTkWXQ0g'
    //                 style={mapStyles}
    //                 initialCenter={{ lat: 47.444, lng: -122.176}}
    //                 >
    //                 <Marker position={{ lat: 48.00, lng: -122.00}} />
    //             </Map>


    //         </div>
            
    //     );
    // }

}

export default GoogleApiWrapper({
   apiKey: 'AIzaSyD3szBTvuS1BmWVPo9bxpMs94yJevJrvIo'//'AIzaSyBWnhCXaUiBJ3a58TB_LndoUCsrTkWXQ0g'
})(MapContainer);