import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import {Link} from 'react-router-dom';
import User from '../components/User';

//import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';



class Club extends Component {

    constructor(props) {
        super(props);
        this.state = {
            club: [],
            rooms: [],
            room: 0,
            users: []
        }
    }

    componentDidMount(){
        this.updateClub();
        this.getRooms();
    }

    updateClub=()=>{
        Service.get('/users/getClub',{id: this.props.match.params.id},(res)=>{
            console.log(res);
            if(res.success){
                this.setState({club: res.data.club});
            }

        })
    }

    getRooms(){
        Service.get('/users/getRooms',{clubId: this.props.match.params.id},(res)=>{
            console.log(res);
            if(res.success){
                this.setState({rooms: res.data.rooms});
            }

        })
    }

    updateUsers=()=>{
        Service.get('/users/getRoomUsers',{roomId: this.state.room},(res)=>{
            console.log(res);
            if(res.success){
                this.setState({users: res.data.users});
            }

        })
    }

    enterRoom=(event)=>{
        console.log(event.target.id);
        let roomId = event.target.id;
        this.setState({room: roomId}, ()=>{
            Auth.room = roomId;
            this.updateUsers();
        });
    }

    move=(event)=>{
        event.stopPropagation();
        console.log(event.currentTarget);
        if(event.currentTarget.id === 'room'){
            let w = event.currentTarget.offsetWidth;
            let h = event.currentTarget.offsetHeight;

            console.log('move', event.nativeEvent.offsetX / w * 100,  event.nativeEvent.offsetY / h * 100)
            let x = event.nativeEvent.offsetX / w * 100;
            let y = event.nativeEvent.offsetY / h * 100
            Service.get('/users/moveUser',{x: x, y: y},(res)=>{
                console.log(res);
                if(res.success){
                    this.updateUsers();
                }

            })
        }else{
            console.log('cls', event.currentTarget.id)
        }
    
    }

    handleChildClick=(e)=>{
        e.stopPropagation();
        console.log('child');
    }
    



    render() {
        return (
            <div>
                <div id="room" className="room" onClick={this.move}>
                    {this.state.users.map(function(item, index){
                                return(
                                    
                                    <div class="club-user" id={item.id} onClick={this.handleChildClick} style={{left: item.x+'%', top: item.y+'%'}} >
                                        {/* {item.name} */}
                                        {/* <img src={item.image} /> */}
                                        <User user={item} updateUsers={this.updateUsers}/>
                                    </div>
                                )
                            },this)
                        }
                </div>
                <div className="club-data">
                    <h3>Welcome to the</h3>
                    <h1>{this.state.club.name}</h1>
                    <p>{this.state.club.description}</p>
                    <div>
                        <h3>Rooms:</h3>
                        {this.state.rooms.map(function(item, index){
                                return(
                                    <button id={item.id} onClick={this.enterRoom} >{item.name} </button>
                                )
                            },this)
                        }
                    </div>
                    <Link to='/'>Leave the club</Link>
                </div>
            </div>
            
        );
    }

}

export default Club;